<template>
  <div class="bill_detail">
    <back />

    <div class="info" v-loading="loading">
      <div class="title">详细信息</div>
      <el-row :gutter="20">
        <el-col :span="5">
          <div>订单编号：{{ obj.orderCode }}</div>
        </el-col>
        <el-col :span="5">
          <div>订单类型：{{ orderType[obj.orderType] }}</div>
        </el-col>
        <el-col :span="5">
          <div>店铺名称：{{ obj.shopName }}</div>
        </el-col>
        <el-col :span="5">
          <div>订单状态：{{ orderStatus[obj.orderStatus] }}</div>
        </el-col>
        <el-col :span="5">
          <div>订单子状态：{{ deliveryStatus[obj.deliveryStatus] }}</div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="5">
          <div>支付状态：{{ obj.payStatus }}</div>
        </el-col>
        <el-col :span="5">
          <div>订单金额：{{ obj.orderAmount || 0 }}元</div>
        </el-col>
        <el-col :span="5">
          <div>骑手配送费：{{ obj.deliveryAmount || 0 }}元</div>
        </el-col>
        <el-col :span="5">
          <div>运费金额：{{ obj.freight || 0 }}元</div>
        </el-col>
        <el-col :span="5">
          <div>打包费用：{{ obj.packAmount || 0 }}元</div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="5">
          <div>支付金额：{{ obj.payAmount || 0 }}元</div>
        </el-col>
        <el-col :span="5">
          <div>优惠金额：{{ obj.discountAmount || 0 }}元</div>
        </el-col>
        <el-col :span="5">
          <div>运费加价：{{ obj.shopAddFreight || 0 }}元</div>
        </el-col>
        <el-col :span="5">
          <div>下单时间：{{ obj.orderTime }}</div>
        </el-col>
        <el-col :span="5">
          <div>付款时间：{{ obj.paymentTime }}</div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="5">
          <div>发货时间：{{ obj.deliveryTime }}</div>
        </el-col>
        <el-col :span="5">
          <div>完成时间：{{ obj.completionTime }}</div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="30">
          <div>备注：{{ obj.buyerNotes }}</div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="title">骑手信息</div>
      <el-row :gutter="20">
        <el-col :span="5">
          <div>骑手编号：{{ obj.riderNum }}</div>
        </el-col>
        <el-col :span="5">
          <div>骑手姓名：{{ obj.riderName }}</div>
        </el-col>
        <el-col :span="5">
          <div>手机号码：{{ obj.riderPhone }}</div>
        </el-col>
        <el-col :span="5">
          <div>接单时间：{{ obj.riderOrderTime }}</div>
        </el-col>
        <el-col :span="5">
          <div>取货时间：{{ obj.shopEstimatedTime }}</div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="5">
          <div>送达时间：{{ obj.completionTime }}</div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="title">用户信息</div>
      <el-row :gutter="20">
        <el-col :span="5">
          <div>用户编号：{{ obj.userCode }}</div>
        </el-col>
        <el-col :span="5">
          <div>用户名称：{{ obj.userName }}</div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="title">收货信息</div>
      <el-row :gutter="20">
        <el-col :span="5">
          <div>收货人姓名：{{ obj.consigneeName }}</div>
        </el-col>
        <el-col :span="5">
          <div>收货电话：{{ obj.contactPhone }}</div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="30">
          <div>收货地址：{{ obj.address }}</div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="title">商品信息</div>
      <el-row :gutter="20">
        <div class="globle_table">
          <el-table :data="obj?.selectOnlineOrdersProductVoList" style="width: 100%" max-height="540">
            <el-table-column label="序号" width="80">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip min-width="120" prop="" label="商品图片">
              <template slot-scope="scope">
                <span style="color: #069bbc; cursor: pointer" @click="previewImage(scope.row.picUrl)">查看图片</span>
              </template>
            </el-table-column>
            <!-- <el-table-column show-overflow-tooltip min-width="120" prop="serialId" label="商品编号"></el-table-column> -->
            <el-table-column show-overflow-tooltip min-width="160" prop="barCode" label="商品条形码"></el-table-column>
            <el-table-column show-overflow-tooltip min-width="150" prop="productName" label="商品名称"></el-table-column>
            <el-table-column show-overflow-tooltip min-width="120" prop="specs" label="规格"></el-table-column>
            <el-table-column show-overflow-tooltip min-width="120" prop="categoryName" label="商品类目"></el-table-column>
            <el-table-column show-overflow-tooltip min-width="120" prop="brand" label="品牌"></el-table-column>
            <el-table-column show-overflow-tooltip min-width="120" prop="productPrice" label="售价(元)"></el-table-column>
            <el-table-column show-overflow-tooltip min-width="120" prop="productCount" label="购买数量"></el-table-column>
          </el-table>
        </div>
      </el-row>
      <div class="totals">
        <div>商品总价：￥{{ obj.orderAmount || 0 }}元</div>
        <div>运费：￥{{ obj.freight || 0 }}元</div>
        <div>打包费：￥{{ obj.packAmount || 0 }}元</div>
        <div>优惠：￥{{ obj.discountAmount || 0 }}元</div>
        <div>
          应付：
          <span style="color: red; font-weight: bold">
            <span>￥</span>
            <span style="font-size: 26px">{{ obj.payAmount || 0 }}</span>
            <span style="color: rgba(3, 16, 14, 0.6); font-weight: normal">元</span>
          </span>
        </div>
      </div>
    </div>

    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false
        }
      "
      :url-list="imgList"
    ></el-image-viewer>
  </div>
</template>

<script>
export default {
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      loading: false,
      showViewer: false,
      obj: {},
      tableData: [],
      imgList: [],
      orderType: { 1: "线下订单", 2: "线上配送单", 3: "线上自提单" },
      deliveryStatus: { 0: "待付款", 1: "待接单", 2: "备货中", 3: "骑手配送中", 4: "已完成", 5: "待自提", 6: "售后中", 7: "待退款", 8: "已退款", 9: "已取消" },
      orderStatus: { "-1": "用户取消", 0: "待付款", 1: "待收货", 4: "已完成", 5: "已取消" },
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    // 图片预览
    previewImage(url) {
      this.imgList[0] = url
      this.showViewer = true
    },
    getDetail() {
      this.loading = true
      this.$axios
        .get(this.$api.shopMemberBillOnlineOrdersInfo, {
          params: {
            orderCode: this.$route.query.code || null,
            shopId: this.$store.state.userInfo.shopId || null,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.obj = res.data.result
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.bill_detail {
  .info {
    padding: 30px;
    box-sizing: border-box;

    .title {
      color: #1a1a1a;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .form {
      margin-top: 30px;

      .el-form-item {
        margin-bottom: 30px;
      }

      .el-input {
        width: 350px;

        ::v-deep .el-input__inner {
          border: none;
          background-color: #fff;
          cursor: default;
        }
      }

      ::v-deep .el-form-item {
        margin-bottom: 15px;
      }

      ::v-deep .el-form-item__label {
        color: #1a1a1a;
        font-size: 16px;
        padding: 0;
      }
    }
    .el-row {
      margin-bottom: 20px;
    }
    .el-col-5 {
      width: 20%;
    }
    .totals {
      display: flex;
      div {
        display: flex;
        align-items: center;
        color: rgba(3, 16, 14, 0.6);
        margin-right: 30px;
      }
    }
  }
  ::v-deep .el-divider {
    background-color: rgba(3, 16, 14, 0.06);
  }
}
</style>
